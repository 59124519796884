import { ConsignmentReturnModel, ConsignmentUpdateModel } from "@g3r-developers/vamp-types/dist";
import { AcceptConsignmentModel } from "@g3r-developers/vamp-types/dist/Consignment/AcceptConsignmentModel";
import { ConsignmentDataTableModel } from "@g3r-developers/vamp-types/dist/Consignment/ConsignmentDataTableModel";
import { ConsignmentStatsModel } from "@g3r-developers/vamp-types/dist/Consignment/ConsignmentStatsModel";
import { MarkAsNoPodModel } from "@g3r-developers/vamp-types/dist/Consignment/MarkAsNoPodModel";
import { SimpleConsignmentModel } from "@g3r-developers/vamp-types/dist/Consignment/SimpleConsignmentModel";
import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { MediaModel } from "@g3r-developers/vamp-types/dist/media/media-model";
import api from "services/useApi";
import { ConsignmentSearchModel } from "types/Consignment/ConsignmentSearchModel";

const acceptConsignmentWithToken = async (consignmentId: number, model: AcceptConsignmentModel): Promise<void> => {
    return api.post(`consignment/supplier/accept/${consignmentId}`, model);
};

const getById = (consignmentId: number): Promise<SimpleConsignmentModel> => {
    return api.get(`consignment/${consignmentId}`);
};

const getStats = async (model?: ConsignmentSearchModel): Promise<ConsignmentStatsModel> =>
    api.get<ConsignmentStatsModel>(`consignment/stats`, model ?? {});

const notifySupplier = (consignmentId: number): Promise<void> => {
    return api.post(`consignment/${consignmentId}/notify-supplier`);
};

const search = async (model: ConsignmentSearchModel): Promise<DataTableResult<ConsignmentDataTableModel>> =>
    api.get<DataTableResult<ConsignmentDataTableModel>>(`consignment`, model);

const uploadPod = (consignmentId: number, file: File): Promise<MediaModel> => {
    return api.postFile(`consignment/${consignmentId}/upload-pod`, file);
};

const markAsNoPod = (model: MarkAsNoPodModel) => {
    return api.post(`consignment/${model.consignment_id}/mark-as-no-pod`, model);
};

const update = async (consignmentId: number, model: ConsignmentUpdateModel): Promise<ConsignmentReturnModel> =>
    api.patch(`consignment/${consignmentId}`, model);

const ConsignmentService = {
    acceptConsignmentWithToken,
    getById,
    getStats,
    markAsNoPod,
    notifySupplier,
    search,
    update,
    uploadPod,
};

export default ConsignmentService;
