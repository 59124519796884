import ProfilePic from "lib/common/profile-pic";
import { ListGroupItem } from "reactstrap";
import { NoteModel } from "types/note/note-model";
import { NoteDisplayCleanText } from "./NoteDisplayCleanText/NoteDisplayCleanText";

export const NoteDisplay = ({ note }: { note: NoteModel }) => {
    return (
        <ListGroupItem className="activity-item d-flex" key={`note-${note.note_id}`}>
            <div className="me-3">
                <ProfilePic media={note.created_contact?.profile_picture} width={50} />
            </div>
            <div className="mt-2 ms-2">
                <div className="mb-0 text-muted font-sm">
                    {note.created_contact?.full_name ?? "Inactive User"} @ {note.added_string}
                </div>
                <h6 style={{ whiteSpace: "pre-line" }}>
                    <NoteDisplayCleanText note_text={note.note_text} />
                </h6>
            </div>
        </ListGroupItem>
    );
};
