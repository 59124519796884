import { useMemo } from "react";
import { NoteWord } from "../NoteWord";

export const NoteDisplayCleanText = ({ note_text }: { note_text: string }) => {
    const noteText = useMemo(() => {
        const split = note_text.split("[[");
        const final = [];
        split.forEach(s => {
            const split2 = s.split("]]");
            split2.forEach(s2 => {
                final.push(s2);
            });
        });

        return (
            <>
                {final.map(str => (
                    <NoteWord key={str} word={str} />
                ))}
            </>
        );
    }, [note_text]);

    return <span>{noteText}</span>;
};
